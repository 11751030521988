import axios from 'axios'
import { Toast } from 'vant'
import settings from '@/settings'
import { getCodeApi, go } from "@/utils";
import { getExpiresOut, getToken, removeToken } from './auth'
import { openIdKey } from './data'
import { refreshToken } from '@/api'

// 加载框实例
let loading = null
// 关闭加载框
const closeLoading = () => loading?.clear()
// 显示加载框
function showLoading() {
    closeLoading()
    loading = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
    });
}

const service = axios.create({
    baseURL: settings.baseURL,
    timeout: 5000, // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        config.params = { ...config.params, wechatCode: settings.wechatCode }
        config.loading && showLoading()
        let token = getToken()
        if (token) {
            config.params = { ...config.params, access_token: token }
            // token过期
            if (getExpiresOut() && getExpiresOut() < Date.now() && !config.params.refresh_token)
                return Promise.reject("refreshToken")
        }
        return config
    },
    error => Promise.reject(error),
)

service.interceptors.response.use(
    response => {
        closeLoading()
        const res = response.data
        // 文件下载，直接返回
        if (res.code === undefined) return res

        // 1202：token无效
        // 1203：access token不能为空
        // 1204：token过期
        // 1205：账号在另一地点登录，被迫下线
        // 2204：页面刷新时，code失效
        // 3010：微信授权登录 - 未绑定会员
        // 9502：location2Area接口的地址不存在
        if (res.code !== 0) {
            if (res.code === 2204) return getCodeApi()
            if (res.code === 3010) {
                let msg = res.msg.split(',')
                openIdKey.value = msg[1]
                return Promise.reject(new Error(msg[0] || '请求出错'))
            }
            if (res.code !== 9502 && res.code !== 1202 && res.code !== 1203) {
                Toast.fail(res.message || res.msg || '请求出错')
            }
            if (res.code === 1205 || res.code === 1202) {
                removeToken()
                go("/login")
            }
            if (res.code === 1204) return Promise.reject("refreshToken")
            return Promise.reject(new Error(res.message || '请求出错'))
        }
        return res
    },
    error => {
        closeLoading()
        error.message && Toast.fail(error.message)
        return Promise.reject(error)
    },
)

async function request(config) {
    try {
        return await service(config)
    } catch (e) {
        // token过期，刷新token后重新请求
        if (e === 'refreshToken') {
            await refreshToken()
            return await service(config)
        }
        return Promise.reject(e)
    }
}

export default request
