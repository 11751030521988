const type = process.env.VUE_APP_TYPE // [VOC_LOCK, XKS_LOCK, XL_LOCK, RC_LOCK, HSH_LOCK]
let wechatCode = "VOC_LOCK" // 接口传输的type
let title = "VOC服务管理系统" // 分享的标题
let logo = "https://aftersale.voc.so/app/attachment/2021_04_26_17bd081d-db79-49b2-bcb0-a58d8056cdec.png?attachmentType=ADVERT"
let appid = "wx77e8f0d1b45a571d"
let suffix = "wechat" // url后缀
let Authorization = "Basic Q0xJRU5UX01FTUJFUl9XRUNIQVQ6NzdIVTZ2b2MmJg=="
let banner = "MINE" // “我的”的banner
let name = "VOC" // 简称-名字
let servicePhone = "400-60-88877" // 400电话
let contact = "http://affim.baidu.com/cps/chat?siteId=16672415&userId=25649174&siteToken=8682cf0db4b5d24d22473fdcca31bbb5"
let channelSelectedId = "" // 渠道默认选中ID（适应星落）
let canScan = false // 预约安装开启扫码功能
let company = "浙江威欧希科技股份有限公司" // 公司中文名
let repairDate = "一" // 保修期
let getLocal = true // 是否获取地址信息
switch (type) {
    case "XKS_LOCK":
        wechatCode = "XKS_LOCK"
        title = "小K士服务管理系统"
        logo = "https://aftersale.voc.so/app/attachment/2022_03_05_854abbc5-db01-4cb6-93b3-42a85b9c3ee0.png?attachmentType=ADVERT"
        appid = "wx4d4232c87dff16d6"
        suffix = "xkswechat"
        Authorization = "Basic Q0xJRU5UX01FTUJFUl9XRUNIQVRfWEtTOjc3SFU2dm9jJiY="
        banner = "XKS_MINE"
        name = "小K士"
        servicePhone = "400-85-00088"
        contact = "http://affim.baidu.com/cps/chat?siteId=16672415&userId=25649174&siteToken=8682cf0db4b5d24d22473fdcca31bbb5"
        company = "小K士"
        break;
    case "XL_LOCK":
        title = "星络服务管理系统"
        logo = "https://aftersale.voc.so/app/attachment/2022_05_24_d1b3c2ca-c0cc-4e7c-a61d-822f9b1ae2c5.png?attachmentType=ADVERT"
        suffix = "xlwechat"
        banner = "XINGLUO_MINE"
        name = "星络"
        servicePhone = "400-002-0666"
        channelSelectedId = 66
        canScan = true
        company = "星络"
        repairDate = "二"
        break;
    case "RC_LOCK":
        suffix = "rcwechat"
        channelSelectedId = 71
        canScan = true
        break;
    case "HSH_LOCK":
        title = "汇守护服务管理系统"
        logo = "https://aftersale.voc.so/app/attachment/2023_05_05_fe54a5e7-7e8a-44da-b0c0-8c05e92c7028.png?attachmentType=ADVERT"
        suffix = "zhaoshangwechat"
        banner = "ZHAOSHANG_MINE"
        name = "汇守护"
        servicePhone = ""
        channelSelectedId = 77
        canScan = true
        company = "汇守护"
        getLocal = false
        break;
}
module.exports = {
    wechatCode,
    title,
    logo,
    baseURL: "https://aftersale.voc.so",
    // baseURL: "https://aftersale.wzxsoft.com",
    // baseURL: "http://192.168.200.57:9696",
    v: '2.3.5',
    type,
    appid,
    suffix,
    Authorization,
    banner,
    name,
    servicePhone,
    contact,
    channelSelectedId,
    canScan,
    company,
    repairDate,
    getLocal
}
