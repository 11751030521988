// 权限管理
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' // 进度条样式
import router, { whiteList } from './router'

import { getCodeApi, isWeixin, wxConfigInit, wxShare } from "@/utils"; // 微信授权
import { getToken, removeToken } from '@/utils/auth' // Token设置
import { includeViews, includeScrollTop, previewList, configUrl } from "@/utils/data";

import settings from '@/settings'
import { login } from "@/api";

// code，用于判断是否微信授权过
let code = "";
// 获取微信登录时的code
const GetQueryString = (name) => {
    var url = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var newUrl = window.location.search.substr(1).match(url);
    return newUrl != null ? unescape(newUrl[2]) : ""
};
// 前往登录页面，且带上当前路径以便返回
const toLoginPath = (to) => {
    return {
        path: '/login',
        query: { ...to.query, path: encodeURIComponent(to.path) }
    }
}

NProgress.configure({ showSpinner: false }) // 设置进度条

router.beforeEach(async (to, from, next) => {
    // 对缓存的页面进行滚动高度缓存(.main-list或者window的滚动高度)
    let position = document.querySelector(".main-list")?.scrollTop || window.scrollY;
    if (includeViews.value.includes(from.name)) {
        includeScrollTop[from.name] = position // 需要缓存的存储滚动高度
    } else {
        delete includeScrollTop[from.name] // 不需要缓存的清空滚动高度
    }

    // 页面返回时，关闭预览图片
    previewList.value.forEach(item => item.close())
    if (previewList.value.length) return next(false)

    // 进度条开始加载
    NProgress.start()

    // 设置页面标题
    document.title = to.meta?.title || settings.title
    
    if (whiteList.indexOf(to.path) !== -1) {
        // 免登陆页面，直接进入
        next()
    } else if (getToken()) {
        // 已有token，直接进入
        if (to.path === '/login') {
            // login页跳转到首页
            next({ path: '/' })
            NProgress.done() // 进度条加载完毕（防止'/'跳转'/'不执行afterEach）
        } else {
            next()
        }
    } else if (isWeixin() && !code) {
        // 微信端且未授权
        if (GetQueryString("code")) {
            // 微信授权成功，获取code
            code = GetQueryString("code")
            configUrl.value = window.location.href // 记录授权返回url，用户wx.config
            wxConfigInit().then(() => wxShare()); // 页面初始化（wxConfig配置，微信分享）
            let url = window.location.href.replace(window.location.search, "")
            window.history.pushState(null, null, url); // 更改url（去除微信登录后的#和?产生的url错误）

            // 微信登录
            try {
                await login({ loginType: "MEMBER_WECHAT", code })
                if (to.path === '/login') {
                    // login页跳转到首页
                    next({ path: '/' })
                    NProgress.done() // 进度条加载完毕（防止'/'跳转'/'不执行afterEach）
                } else {
                    next()
                }
            } catch {
                removeToken()
                // 授权登录失败
                next(toLoginPath(to))
            }
        } else {
            // 跳转微信授权登录
            getCodeApi();
        }
    } else {
        // 没有token，跳转到登录页面
        to.path === '/login' ? next() : next(toLoginPath(to))
        NProgress.done() // 进度条加载完毕
    }
})

router.afterEach((to) => {
    NProgress.done() // 进度条加载完毕

    // 对缓存的页面进行滚动高度加载
    let position = includeScrollTop[to.name]
    position && setTimeout(() => {
        if (includeViews.value.includes(to.name)) {
            document.querySelector('.main-list') ? (document.querySelector(".main-list").scrollTop = position) : window.scrollTo(0, position)
        }
    }, 0);
})
