<template>
    <div class="layout-container">
        <van-nav-bar
            class="navbar"
            :title="$route.meta?.title"
            left-arrow
            @click-left="goBack"
        />
        <router-view v-slot="{ Component }">
            <keep-alive :include="includeViews">
                <component :is="Component" />
            </keep-alive>
        </router-view>
    </div>
</template>

<script>
import { goBack } from "@/utils";
import { includeViews } from "@/utils/data";

export default {
    setup() {
        return {
            includeViews, // 需要缓存的页面
            goBack,
        };
    },
};
</script>

<style lang="less" scoped>
@import url("@/styles/mixin.less");
@import url("vant/lib/nav-bar/index.less");
.layout-container {
    .flex(stretch, stretch, column);
    padding-top: @nav-bar-height;
    box-sizing: border-box;
    min-height: 100vh;
    .navbar {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        box-shadow: 0 0 2px #dcdcdc;
        z-index: 3;
    }
}
</style>
