<template>
    <router-view />
</template>

<script>
import { getCurrentInstance, watch } from "vue";
import { includeViews } from "@/utils/data";

export default {
    name: "App",
    setup() {
        let { proxy } = getCurrentInstance();
        watch(
            () => proxy.$route,
            (to, from) => {
                //如果 要 to(进入) 的页面是需要 keepAlive 缓存的，把 name push 进 include数组
                if (to.meta.keepAlive) {
                    !includeViews.value.includes(to.name) &&
                        includeViews.value.push(to.name);
                }
                //如果 要 form(离开) 的页面是 keepAlive缓存的，
                //再根据 deepth 来判断是前进还是后退
                //如果是后退
                if (
                    from?.meta?.keepAlive &&
                    to.meta.deepth < from.meta.deepth
                ) {
                    var index = includeViews.value.indexOf(from.name);
                    index !== -1 && includeViews.value.splice(index, 1);
                }
            },
            { immediate: true }
        );
    },
};
</script>

<style>
.van-cell__title,
.van-cell__value.van-cell__value--alone {
    padding-left: 5px;
}
</style>
