<template>
    <div class="tabbar-container">
        <router-view v-slot="{ Component }">
            <keep-alive :include="includeViews">
                <component :is="Component" />
            </keep-alive>
        </router-view>
        <van-tabbar class="tabbar" route>
            <van-tabbar-item replace to="/home">
                服务中心
                <template #icon="props">
                    <img :src="props.active ? home_active : home_icon" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/person">
                个人中心
                <template #icon="props">
                    <img :src="props.active ? my_active : my_icon" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
import { includeViews } from "@/utils/data";

export default {
    setup() {
        return {
            home_icon: require("@/assets/icon/home.png"),
            home_active: require("@/assets/icon/home_active.png"),
            my_icon: require("@/assets/icon/my.png"),
            my_active: require("@/assets/icon/my_active.png"),
            includeViews, // 需要缓存的页面
        };
    },
};
</script>

<style lang="less" scoped>
@import url("vant/lib/tabbar/index.less");
@import url("@/styles/mixin.less");
.tabbar-container {
    .flex(stretch, stretch, column);
    padding-bottom: @tabbar-height;
    box-sizing: border-box;
    min-height: 100vh;
    .tabbar {
        box-shadow: 0 0 2px #dcdcdc;
    }
}
</style>
