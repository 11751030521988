import { createRouter, createWebHashHistory } from 'vue-router'

import Layout from '@/views/Layout'
import Tabbar from '@/views/Tabbar'
// 免登陆页面
export const whiteList = [
    '/register',
    '/forgetPassword',
    '/protocol',
    '/privacy',
    '/fault',
    '/faultDetail',
    '/policy',
]

const routes = [
    {
        path: '/login',
        name: 'Login',
        meta: { title: '登录', deepth: 0, },
        component: () => import('@/views/login/Login.vue')
    },

    {
        component: Tabbar,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
                meta: { title: '主页', deepth: 1, },
                component: () => import('@/views/index/Home.vue')
            },
            {
                path: '/person',
                name: 'Person',
                meta: { title: '个人中心', deepth: 1, },
                component: () => import('@/views/person/Person.vue')
            },
        ]
    },
    {
        component: Layout,
        children: [
            // login
            {
                path: '/protocol',
                name: 'Protocol',
                meta: { title: '用户管理协议', deepth: 2, },
                component: () => import('@/views/login/Protocol.vue')
            },
            {
                path: '/privacy',
                name: 'Privacy',
                meta: { title: '用户隐私政策', deepth: 2, },
                component: () => import('@/views/login/Privacy.vue')
            },

            // person
            {
                path: '/setting',
                name: 'Setting',
                meta: { title: '设置', deepth: 2, },
                component: () => import('@/views/person/Setting.vue')
            },
            {
                path: '/modifyPassword',
                name: 'ModifyPassword',
                meta: { title: '修改密码', deepth: 3, },
                component: () => import('@/views/person/ModifyPassword.vue')
            },

            // policy
            {
                path: '/policy',
                name: 'Policy',
                meta: { title: '服务政策', deepth: 2, },
                component: () => import('@/views/policy/Policy.vue')
            },

            // fault
            {
                path: '/fault',
                name: 'Fault',
                meta: { title: '故障快查', deepth: 2, },
                component: () => import('@/views/fault/Fault.vue')
            },
            {
                path: '/faultDetail',
                name: 'FaultDetail',
                meta: { title: '查询结果', deepth: 3, },
                component: () => import('@/views/fault/FaultDetail.vue')
            },
        ]
    },
    { path: '/:catchAll(.*)', redirect: '/home' }
]


const router = createRouter({
    history: createWebHashHistory(),
    scrollBehavior: () => ({ top: 0 }),
    routes
})

export default router
