import "core-js/stable";
import "regenerator-runtime/runtime";

// 禁用浏览器滚动，允许盒子滚动，防止微信浏览器下拉黑屏
let scrollView = [
    '#app',
    '.main-list',
    '.van-calendar__body',
    '.van-cascader__options'
]
var touchstartY;
document.body.addEventListener(
    "touchstart",
    function (event) {
        var events = event.touches[0] || event;
        touchstartY = events.clientY; //获取触摸目标在视口中的y坐标
    },
    false
);

document.body.addEventListener(
    "touchmove",
    function (event) {
        var events = event.touches[0] || event;
        var scrollTop = document.documentElement.scrollTop || window.scrollY
        for (let i = 0; i < scrollView.length; i++) {
            if (scrollTop) break;
            const els = document.querySelectorAll(scrollView[i]);
            scrollTop = scrollTop || 0
            els.forEach(el => scrollTop = scrollTop || el?.scrollTop)
        }
        if (
            events.clientY > touchstartY &&
            scrollTop === 0 &&
            event.cancelable
        ) {
            event.preventDefault(); //禁止到顶下拉
        }
        touchstartY = events.clientY
    },
    { passive: false }
);

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import '@vant/touch-emulator';
import 'default-passive-events'
import './fonts/iconfont.css';

import Vant from 'vant';
import 'vant/lib/index.css';

import './permission' // 权限控制
import '@/styles/index.less' // 样式

createApp(App).use(router).use(Vant).mount('#app')
