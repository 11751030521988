import request from '@/utils/request'
import { Toast } from 'vant';
import { go } from '@/utils';
import { setToken, getRefreshToken, removeToken } from "@/utils/auth";
import { openIdKey, includeViews } from "@/utils/data";
import settings from "@/settings";
const Authorization = settings.Authorization

// 系统时间
export function currentDate() {
    return request({
        url: '/app/currentDate',
        method: 'get',
    })
}

// 忘记密码
export function forgetPassword(data) {
    return request({
        url: '/app/forgetPassword',
        method: 'post',
        data
    })
}

// 会员登录
export async function login(form) {
    let { data } = await request({
        url: '/app/login',
        method: 'post',
        headers: { Authorization },
        data: form
    })
    setToken(data);
    Toast.success("登录成功");
}

// 退出登录
export async function logout() {
    let { data } = await request({
        url: '/app/logout',
        method: 'post'
    })
    if (data) openIdKey.value = data
    removeToken();
    includeViews.value = []
    go("/login")
}

// 个人信息
export function mine(data) {
    return request({
        url: '/app/mine',
        method: 'get',
        data
    })
}

// 修改密码
export function modifyPassword(data) {
    return request({
        url: '/app/modifyPassword',
        method: 'post',
        data
    })
}

// 多次请求refreshToken，仅记住第一次请求
let refreshFunc = null
// 刷新token
export async function refreshToken() {
    try {
        if (!refreshFunc)
            refreshFunc = request({
                url: '/app/refreshToken',
                method: 'post',
                headers: { Authorization },
                params: { refresh_token: getRefreshToken() },
            })
        let { data } = await refreshFunc
        refreshFunc = null
        setToken(data);
    } catch (err) {
        removeToken()
        refreshFunc = null
        window.location.reload()
    }
}

// 会员注册
export function register(data) {
    return request({
        url: '/app/register',
        method: 'post',
        data
    })
}

// 微信签名
export function wxConfig(url) {
    return request({
        url: '/app/wxConfig',
        method: 'get',
        params: { url }
    })
}
