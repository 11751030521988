import wx from "weixin-js-sdk";
import { Toast, ImagePreview } from 'vant';
import { configUrl, previewList } from "./data";
import { getToken } from './auth'
import { wxConfig } from "@/api";
import router from "@/router";
import settings from '@/settings'

/**
 * 防抖
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
 export function debounce(func, wait, immediate) {
    let timeout, args, context, timestamp, result
    // 为了适应 Promise 没有 .apply
    const _func = (...args0) => func(...args0)

    const later = function () {
        // 据上一次触发时间间隔
        const last = +new Date() - timestamp

        // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last)
        } else {
            timeout = null
            // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
            if (!immediate) {
                result = _func.apply(context, args)
                if (!timeout) context = args = null
            }
        }
    }

    return function (...args0) {
        context = this
        args = args0
        timestamp = +new Date()
        const callNow = immediate && !timeout
        // 如果延时不存在，重新设定延时
        if (!timeout) timeout = setTimeout(later, wait)
        if (callNow) {
            result = _func.apply(context, args)
            context = args = null
        }

        return result
    }
}

// 是否微信浏览器
export const isWeixin = () =>
    !!navigator.userAgent.toLowerCase().match(/MicroMessenger/i)

// 判断手机系统
export function isAndroid() {
    let u = navigator.userAgent
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
    return isAndroid
}

// 微信授权登录
export function getCodeApi(state = "") {
    //获取code
    let urlNow = encodeURIComponent(window.location.href);
    let scope = "snsapi_userinfo";
    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${settings.appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
    window.location.replace(url);
}

// 文字换行转换
export const wordWrap = (text) => text.replace(/\n/g, '<br />')

// 初始化wxConfig（页面加载时执行）
export async function wxConfigInit() {
    if (!isWeixin()) return Promise.reject('请使用微信浏览器')
    if (!configUrl.value) return Promise.reject('请先微信授权')
    let { data } = await wxConfig(isAndroid() ? 'https://aftersale.voc.so/' + settings.suffix + '/' : configUrl.value)

    wx.config({
        debug: false,
        appId: data.appId, // 必填,公众号的唯一标识
        timestamp: data.timestamp, // 必填,生成签名的时间戳
        nonceStr: data.nonceStr, // 必填,生成签名的随机串
        signature: data.signature, // 必填,签名
        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData", "getLocation", "scanQRCode", "checkJsApi"], // 必填,需要使用的JS接口列表
    })

    /* 处理失败验证 */
    wx.error(function (res) {
        // config 信息验证失败会执行error函数,如签名过期导致验证失败,具体错误信息可以打开config的debug模式查看,也可以在返回的res参数中查看,对于SPA可以在这里更新签名
        Promise.reject("配置验证失败: " + res.errMsg)
        Toast.fail("配置验证失败: " + res.errMsg);
    });
    wx.ready(function () {
        Promise.resolve()
    })
}

// 获取微信方法（查看是否可用）
export function wxMethod(method) {
    return new Promise((resolve, reject) => {
        if (!isWeixin()) return reject('请使用微信浏览器')

        // config信息验证成功后会执行ready方法,所有接口调用都必须在config接口获得结果之后
        // config 是一个客户端的异步操作,所以如果需要在页面加载时调用相关接口,则须把相关接口放在ready函数中调用来确保正确执行.对于用户触发是才调用的接口,则可以直接调用,不需要放在ready函数中
        wx.checkJsApi({
            // 判断当前客户端版本是否支持指定JS接口
            jsApiList: [method],
            success: function (res) {
                if (res.checkResult[method] === true) {
                    resolve(wx[method])
                } else {
                    reject("抱歉，当前客户端版本不支持");
                }
            },
            fail: function () {
                // 检测getNetworkType该功能失败时处理
                // reject("检测getNetworkType该功能失败" + JSON.stringify(res));
            },
        })
    })
}

// 微信分享
export function wxShare(obj) {
    if (!isWeixin()) return Promise.reject('请使用微信浏览器')
    if (!obj)
        obj = {
            title: settings.title,
            desc: settings.title,
            link: 'https://aftersale.voc.so/' + settings.suffix + '/#/home',
            imgUrl: settings.logo,
        }

    wxMethod('updateAppMessageShareData').then(method => method(obj))
        .catch((err) => Toast.fail(err?.errMsg || err));
    wxMethod('updateTimelineShareData').then(method => method(obj))
        .catch((err) => Toast.fail(err?.errMsg || err));
}

// 判断字符串是否为空，为空则提示
export async function verifyEmpty(data = "", msg) {
    if (String(data).trim() === "") {
        Toast.fail((msg || data) + '不能为空')
        return Promise.reject((msg || data) + '不能为空')
    }
    return
}

// 验证密码（6-20位）
export async function verifyPassword(data, msg = "密码") {
    let psd = String(data).trim()
    if (psd.length < 6 || psd.length > 20) {
        Toast.fail("请填写6-20位的" + msg)
        return Promise.reject("请填写6-20位的" + msg)
    }
    return
}

// 功能开发中
export const isDevelop = () => Toast('该功能正在开发中，敬请期待...')

// base64转图片格式
function base64toFile(dataurl, filename) {
    var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

// 图片压缩
export async function compressImage(file) {
    return new Promise((resolve) => {
        // 大于500KB的jpeg和png图片都缩小像素上传
        if (
            /\/(?:jpeg|png)/i.test(file.file.type) &&
            file.file.size > 500000
        ) {
            console.log('图片开始压缩')
            // 创建Canvas对象(画布)
            let canvas = document.createElement("canvas");
            // 获取对应的CanvasRenderingContext2D对象(画笔)
            let context = canvas.getContext("2d");
            // 创建新的图片对象
            let img = new Image();
            // 指定图片的DataURL(图片的base64编码数据)
            img.src = file.content;
            // 监听浏览器加载图片完成，然后进行进行绘制
            img.onload = () => {
                // 等比例压缩
                let width = img.width;
                let height = img.height;
                let w_ratio = width / 1000
                let h_ratio = height / 1000
                if (w_ratio > 1 && h_ratio > 1) {
                    let ratio = Math.min(w_ratio, h_ratio)
                    width = width / ratio
                    height = height / ratio
                }

                // 指定canvas画布大小，该大小为最后生成图片的大小
                canvas.width = width;
                canvas.height = height;

                context.drawImage(img, 0, 0, width, height);
                // 将绘制完成的图片重新转化为base64编码，file.file.type为图片类型，1为默认压缩质量
                resolve(base64toFile(canvas.toDataURL(file.file.type, 1)))
            };
        } else {
            // 文件非图片或不压缩
            resolve(file.file)
        }
    })
}

export function setUrl(url) {
    const baseURL = settings.baseURL
    const token = getToken() ? "access_token=" + getToken() : ""
    return baseURL + url + (url.includes('?') ? '&' : '?') + token
}

// 图片路径补全
export const getFile = (url, type = "BASE") =>
    setUrl(`/app/attachment/${url}?attachmentType=${type}`)

// 图片预览（网络）
export function previewImage(image, images = "", type) {
    images = images.split(',')
    let index = images.indexOf(image)
    images = images.map(item => getFile(item, type))
    let ImagePreviewDialog = ImagePreview({
        images,
        startPosition: index === -1 ? 0 : index,
        onClose() {
            let index = previewList.value.indexOf(ImagePreviewDialog)
            index !== -1 && previewList.value.splice(index, 1)
        }
    });
    previewList.value.push(ImagePreviewDialog)
}

// 图片预览（本地，单张图片）
export function previewImageLocal(image) {
    let ImagePreviewDialog = ImagePreview({
        images: [image],
        onClose() {
            let index = previewList.value.indexOf(ImagePreviewDialog);
            index !== -1 && previewList.value.splice(index, 1);
        },
    });
    previewList.value.push(ImagePreviewDialog);
}

// 返回上一页
export function goBack() {
    // 没有历史记录则返回首页（防止返回到微信授权页再次授权）
    window.history.state && window.history.state.back
        ? router.back()
        : go("/", {}, true);
}

// 跳转页面
export const go = (path, query = {}, replace = false) =>
    router.push({ path, query, replace })

// 在线客服
export function toContact() {
    window.location.href = settings.contact
}

// 拨打电话
export function callMobile(mobile = "") {
    // 存在非数字，获取前面的数字（配合 XXX转1 的写法）
    if (/[^(\d|\-|\s)]/g.test(mobile)) mobile = mobile.split(/[^(\d|\-|\s)]/g)[0]
    window.location.href = "tel:" + mobile;
}

// 监听页面返回，回到首页
window.addEventListener("popstate", function (e) {
    if (!e?.state?.forward) {
        let url = window.location.href.replace(window.location.search, "")
        window.history.pushState(null, null, url); // 更改url（去除微信登录后的#和?产生的url错误）
        go('/index')
    }
}, false);