import Cookies from 'js-cookie'

const options = process.env.NODE_ENV === 'production'
    ? { path: '', domain: '.wangerdi.cn' }
    : {}

const TokenKey = 'App-Token'
const RefreshTokenKey = 'App-RefreshToken'
const ExpiresOut = 'App-ExpiresOut'
const EmailKey = 'App-Email'
const IdKey = 'App-Id'
const MobileKey = 'App-Mobile'
const NameKey = 'App-Name'
console.log(process)
console.log(process.env)
console.log(process.env.NODE_ENV)
export const getToken = () => Cookies.get(TokenKey, options)
export const getRefreshToken = () => Cookies.get(RefreshTokenKey, options)
export const getExpiresOut = () => Cookies.get(ExpiresOut, options)
export function getUserInfo() {
    return {
        email: Cookies.get(EmailKey, options),
        id: Cookies.get(IdKey, options),
        mobile: Cookies.get(MobileKey, options),
        name: Cookies.get(NameKey, options),
    }
}

export function setToken(token = {}) {
    Cookies.set(TokenKey, token.accessToken || '', options)
    Cookies.set(RefreshTokenKey, token.refreshToken || '', options)
    Cookies.set(ExpiresOut, token.expiresIn * 1000 + Date.now(), options)

    Cookies.set(EmailKey, token.memberEmail || '', options)
    Cookies.set(IdKey, token.memberId || '', options)
    Cookies.set(MobileKey, token.memberMobile || '', options)
    Cookies.set(NameKey, token.memberName || '', options)
}

export function removeToken() {
    Cookies.remove(TokenKey, options)
    Cookies.remove(RefreshTokenKey, options)
    Cookies.remove(ExpiresOut, options)

    Cookies.remove(EmailKey, options)
    Cookies.remove(IdKey, options)
    Cookies.remove(MobileKey, options)
    Cookies.remove(NameKey, options)
}
